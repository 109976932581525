<template>
  <BasicModal
    :action="submitForm"
    :close-on-action="false"
    action-title="Submit request"
    :show-action-button="!form.isSubmitted"
    :show-cancel-button="!form.isSubmitted"
    :disable-action-button="form.isLoading || !isFormValid"
  >
    <template v-if="!form.isSubmitted">
      <template v-if="type === 'nft'">
        <h5>Request NFT collection</h5>
        <p class="mb-6">
          Want to monitor a NFT collection not currently supported? Let us know!
        </p>
        <FormSubmitErrors :formName="formName" />
        <FormInput :formName="formName" fieldName="nftCollectionName" ref="focusElement" />
        <FormInput :formName="formName" fieldName="network" />
        <FormInput :formName="formName" fieldName="contractAddress" />
      </template>
      <template v-else>
        <h5>Request token</h5>
        <p class="mb-6">
          Want to monitor a token not currently supported? Let us know!
        </p>
        <FormSubmitErrors :formName="formName" />
        <FormInput :formName="formName" fieldName="tokenName" ref="focusElement" />
        <FormInput :formName="formName" fieldName="tokenSymbol" />
        <FormInput :formName="formName" fieldName="network" />
        <FormInput :formName="formName" fieldName="contractAddress" />
      </template>
      <LoadingOverlay v-if="form.isLoading" />
    </template>
    <template v-else>
      <div class="success-container">
        <img src="@/assets/images/planet.png" />
        <h4>Thank you!</h4>
        <p v-if="type === 'nft'">
          Your NFT collection request has been submitted.
          <br>
          <br>
          We will contact you if we have further questions :)
        </p>
        <p v-else>
          Your token request has been submitted.
          <br>
          <br>
          We will contact you if we have further questions :)
        </p>
      </div>
    </template>
  </BasicModal>
</template>

<script>

  import { useStore } from 'vuex'

  import useForm from '@/composables/useForm'
  import useRecaptcha from '@/composables/useRecaptcha'

  import FormInput from '@/components/forms/FormInput.vue'
  import BasicModal from '@/components/modals/_BasicModal.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  export default {
    components: {
      FormInput,
      BasicModal,
      LoadingOverlay,
      FormSubmitErrors,
    },
    props: {
      type: {
        type: String,
        required: true,
        validator: (value) => {
          return value === 'nft' || value === 'token'
        },
      },
      networkName: {
        type: String,
        default: '',
      },
    },
    setup(props) {

      // data
      const formName = props.type === 'nft' ? 'nftRequestForm' : 'tokenRequestForm'

      // composables
      const store = useStore()
      const { form, isFormValid, focusElement } = useForm({ formName })
      const { setRecaptchaToken } = useRecaptcha({ formName, action: `${props.type}_request_form` })

      store.commit('forms/SET_FIELD_VALUE', {
        newValue: props.networkName,
        fieldName: 'network',
        formName,
      })

      return {
        form,
        formName,
        isFormValid,
        focusElement,
        setRecaptchaToken,
      }

    },
    methods: {
      submitForm() {
        return this.setRecaptchaToken()
          .then(() => {
            this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .success-container
    @apply flex
    @apply flex-col
    @apply items-center

</style>
